.popup {
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

Button {
    margin-top: 10px;
}

.closeIcon {
    float: right;
    margin-left: 400px;
}